import(/* webpackMode: "eager" */ "/app/app/ui/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/context/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mep-agency/next-iubenda/src/components/ConsentAwareWrapper/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mep-agency/next-iubenda/src/components/IubendaCookieSolutionBanner.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mep-agency/next-iubenda/src/contexts/IubendaContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextUIProvider"] */ "/app/node_modules/@nextui-org/system/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Kanit\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"]}],\"variableName\":\"kanit\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/bootstrap-icons/font/bootstrap-icons.css");
