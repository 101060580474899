import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
}

const env = process.env.NODE_ENV
if (env == 'development') {
    firebaseConfig.authDomain = 'localhost:3000'
} else if (env == 'production') {
    firebaseConfig.authDomain = 'app.toplaytorino.it'
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
auth.useDeviceLanguage()
//const messaging = getMessaging(app)

export { auth }
