'use client'

import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { UserData } from '../app/lib/definitions'

const AuthContext = createContext<{
    user: UserData | undefined
    logout: () => Promise<void>
}>({ user: undefined, logout: async () => {} })

export function AuthProvider({ children }: { children: any }) {
    const [user, setUser] = useState<UserData>()

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const fbUser = (await fetch(
                    `/api/getUser?uid=${user?.uid}`
                ).then((res) => res.json())) as UserData
                setUser(fbUser)
            }
        })
        return () => unsubscribe()
    }, [])

    const logout = () => signOut(auth)

    const authProviderValue = useMemo(() => ({ user, logout }), [user, logout])

    return (
        <AuthContext.Provider value={authProviderValue}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}
